<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
    "
    viewBox="0 0 334 334"
    x="0px"
    class="fill-current"
    y="0px"
    fill-rule="evenodd"
    clip-rule="evenodd"
  >
    <rect fill="none" width="334" height="336" />
    <path
      class="fill-current"
      fill-rule="nonzero"
      d="M39 156c-6,5 -14,3 -17,-4 -4,-7 -2,-16 4,-20l135 -102c4,-3 9,-3 13,0l135 102c5,4 7,13 3,20 -3,7 -11,9 -17,4l-128 -97 -128 97z"
    />
    <path
      class="fill-current"
      d="M280 164l0 126c0,10 -8,19 -19,19l-53 0 0 -96c0,-10 -8,-17 -17,-17l-47 0c-10,0 -17,7 -17,17l0 96 -54 0c-10,0 -19,-9 -19,-19l0 -126 113 -85 113 85z"
    />
  </svg>
</template>
