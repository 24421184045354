<script setup lang="ts"></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    class="fill-current"
    viewBox="0 0 100 100"
    xml:space="preserve"
  >
    <path
      d="M89.628,42.143l-8.642-2.233c-0.599-1.892-1.369-3.707-2.287-5.433l4.242-7.201c0.695-1.178,0.504-2.677-0.463-3.644  l-7.07-7.072c-0.967-0.967-2.465-1.159-3.645-0.464l-7.497,4.417c-1.344-0.659-2.739-1.225-4.179-1.695l-2.184-8.445  C57.563,9.048,56.367,8.123,55,8.123H45c-1.368,0-2.562,0.925-2.904,2.249l-2.183,8.444c-1.68,0.55-3.298,1.233-4.846,2.037  l-7.499-4.418c-1.179-0.693-2.677-0.503-3.645,0.464l-7.07,7.072c-0.967,0.967-1.157,2.466-0.463,3.644l4.506,7.647  c-0.742,1.488-1.375,3.039-1.885,4.646l-8.639,2.233c-1.324,0.342-2.249,1.537-2.249,2.904v10.001c0,1.368,0.925,2.563,2.25,2.904  l8.892,2.298c0.409,1.189,0.877,2.35,1.417,3.473l-4.959,8.417c-0.694,1.178-0.503,2.677,0.464,3.644l7.071,7.071  c0.578,0.578,1.346,0.879,2.122,0.879c0.522,0,1.048-0.136,1.522-0.415l8.119-4.784c1.51,0.788,3.089,1.46,4.727,2.006l2.35,9.089  c0.342,1.324,1.537,2.249,2.904,2.249h10c1.367,0,2.563-0.925,2.904-2.249l2.35-9.089c1.397-0.467,2.754-1.021,4.061-1.665  l8.117,4.783c0.474,0.279,0.999,0.415,1.521,0.415c0.775,0,1.544-0.301,2.122-0.879l7.072-7.071  c0.968-0.967,1.157-2.466,0.464-3.645l-4.695-7.967c0.703-1.367,1.312-2.79,1.817-4.261l8.895-2.299  c1.324-0.342,2.249-1.537,2.249-2.904v-10C91.877,43.68,90.952,42.484,89.628,42.143z M66.875,50c0,9.305-7.57,16.875-16.875,16.875  c-9.305,0-16.875-7.57-16.875-16.875S40.696,33.125,50,33.125C59.305,33.125,66.875,40.695,66.875,50z"
    ></path>
  </svg>
</template>

<style scoped></style>
